/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AKEWeapons } from '../data/endfield-weapons';
import { EndfieldWeaponBox } from './endfield-weapon-box';

interface IProps {
  name: string;
  mode: string;
  onProfile?: boolean;
}

export const EndfieldWeaponInfo: React.FC<IProps> = ({
  name,
  mode,
  onProfile
}) => {
  const weapon = AKEWeapons.find((weapon) => weapon.Name === name);

  if (!weapon) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'data' && (
        <EndfieldWeaponBox weapon={weapon} onProfile={onProfile} />
      )}
    </>
  );
};
