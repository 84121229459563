export const AKEWeapons = [
  {
    ID: '1',
    Name: 'Abraxas',
    Weapon_Type: 'Great Sword',
    Rarity: '6',
    Base_ATK: '490',
    Stat_primary: 'STR',
    Stat_primary_value: '90',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '45%',
    Passive_name: 'Crusher: Armor Breaker',
    Effect:
      'Hitting the enemy with Final Strike also reduces their DEF by <b>39%</b> for 10s. Effect cannot stack. For this duration, if the wielder is the controlled operator, Final Strike also deals <u>25%</u> more Stagger to the said enemy.'
  },
  {
    ID: '2',
    Name: 'Thunderberge',
    Weapon_Type: 'Great Sword',
    Rarity: '6',
    Base_ATK: '445',
    Stat_primary: 'STR',
    Stat_primary_value: '90',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '69%',
    Passive_name: 'Assault: Eye of Talos',
    Effect:
      'For every enemy hit with any skill, <u class="Physical">Physical DMG</u> dealt by the wielder is increased by another <b>40.0%</b> for 10s. Max stacks: <b>10</b> (each stack has its own duration).'
  },
  {
    ID: '3',
    Name: 'Former Finery',
    Weapon_Type: 'Great Sword',
    Rarity: '6',
    Base_ATK: '423',
    Stat_primary: 'Main ATR',
    Stat_primary_value: '63',
    Stat_secondary: 'Ultimate gain eff.',
    Stat_secondary_value: '68%',
    Passive_name: 'Medicant: Mincing Therapy',
    Effect:
      "HP Treatment provided by the wielder further restores <b>9%</b> of wielder's Max HP and grants ATK <b>+18%</b> for 10s to the target. Effect can only trigger once for each operator every 30s."
  },
  {
    ID: '4',
    Name: 'Finishing Call',
    Weapon_Type: 'Great Sword',
    Rarity: '5',
    Base_ATK: '406',
    Stat_primary: 'WILL',
    Stat_primary_value: '90',
    Stat_secondary: 'HP',
    Stat_secondary_value: '90%',
    Passive_name: 'Medicant: Knightly Glory',
    Effect:
      "HP Treatment provided by the wielder further restores <b>13%</b> of wielder's Max HP to the target. Effect can only trigger once for each operator every 30s."
  },
  {
    ID: '5',
    Name: 'Never Dull',
    Weapon_Type: 'Great Sword',
    Rarity: '5',
    Base_ATK: '351',
    Stat_primary: 'STR',
    Stat_primary_value: '90',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '45%',
    Passive_name: 'Crusher: Intensifying Trauma',
    Effect:
      'Hitting enemies with Final Strike also reduces their <u class="Physical">Physical RES</u> by <b>22.4%</b> for 10s. Effect cannot stack.'
  },
  {
    ID: '6',
    Name: 'Exemplar',
    Weapon_Type: 'Great Sword',
    Rarity: '5',
    Base_ATK: '369',
    Stat_primary: 'STR',
    Stat_primary_value: '90',
    Stat_secondary: 'Physical DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Combative: Unearthly Slash',
    Effect:
      'Lifting the enemy also deals <b>700.6%</b> ATK of <u class="Physical">Physical DMG</u>'
  },
  {
    ID: '7',
    Name: 'Oathborn',
    Weapon_Type: 'Great Sword',
    Rarity: '4',
    Base_ATK: '337',
    Stat_primary: 'WILL',
    Stat_primary_value: '63',
    Stat_secondary: 'HP',
    Stat_secondary_value: '63%',
    Passive_name: 'Crusher: Gift of Veritas',
    Effect:
      'When the wielder is the controlled character, hitting an enemy with Final Strike restores <b>5.2%</b> Max HP to self.'
  },
  {
    ID: '8',
    Name: 'Industry 0.1',
    Weapon_Type: 'Great Sword',
    Rarity: '4',
    Base_ATK: '307',
    Stat_primary: 'STR',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '32%',
    Passive_name: 'Suppression: Emergency Boost',
    Effect:
      'Hitting an enemy with a battle skill gives ATK <b>+37.6%</b> for 15s. Effect cannot stack.'
  },
  {
    ID: '9',
    Name: 'Darhoff 7',
    Weapon_Type: 'Great Sword',
    Rarity: '3',
    Base_ATK: '267',
    Stat_primary: 'STR',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '52',
    Passive_name: '',
    Effect: ''
  },
  {
    ID: '10',
    Name: "Heart's Content",
    Weapon_Type: 'Sword',
    Rarity: '6',
    Base_ATK: '445',
    Stat_primary: 'AGI',
    Stat_primary_value: '90',
    Stat_secondary: 'Crit DMG',
    Stat_secondary_value: '90%',
    Passive_name: 'Twilight: Oblivion Beckons',
    Effect:
      'Casting the ultimate also increases the <u class="Physical">Physical DMG</u> dealt by the wielder by <b>221.8%</b> for 15s.'
  },
  {
    ID: '11',
    Name: 'Torrent',
    Weapon_Type: 'Sword',
    Rarity: '6',
    Base_ATK: '423',
    Stat_primary: 'AGI',
    Stat_primary_value: '90',
    Stat_secondary: 'Physical DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Brutality: Relentless Avalanche',
    Effect:
      'Increases <u class="Physical">Physical DMG</u> dealt to Vulnerable enemies by <b>80.0%</b>. Increases <u class="Physical">Physical DMG</u> dealt to Lifted, Knocked Down, and Knocked Back enemies by another <b>40.0%</b>.'
  },
  {
    ID: '12',
    Name: 'Cerulean Resonance',
    Weapon_Type: 'Sword',
    Rarity: '6',
    Base_ATK: '423',
    Stat_primary: 'Main ATR',
    Stat_primary_value: '63',
    Stat_secondary: 'Crit Rate',
    Stat_secondary_value: '30%',
    Passive_name: 'Suppression: Echo of the Woods',
    Effect:
      'Casting a battle skill restores <b>26</b> SP and gives ATK <b>+22%</b> for 12s. Effect can only trigger once every 12s.'
  },
  {
    ID: '13',
    Name: 'The Fifth Heirloom',
    Weapon_Type: 'Sword',
    Rarity: '6',
    Base_ATK: '445',
    Stat_primary: 'INT',
    Stat_primary_value: '90',
    Stat_secondary: 'Basic DMG',
    Stat_secondary_value: '69%',
    Passive_name: 'Detonate: Desiccation Wake',
    Effect:
      'Triggering an Arts Burst gives the wielder ATK <b>+27.0%</b> and Critical Rate <b>+6.0%</b> for 30s. Trigger CD: 1s. Max stacks: <b>4</b> (each stack has its own duration).'
  },
  {
    ID: '14',
    Name: 'Thermite Cutter',
    Weapon_Type: 'Sword',
    Rarity: '5',
    Base_ATK: '369',
    Stat_primary: 'INT',
    Stat_primary_value: '90',
    Stat_secondary: 'Crit DMG',
    Stat_secondary_value: '90%',
    Passive_name: 'Detonate: High Impact',
    Effect: 'Arts Burst DMG <b>+252.0%</b>.'
  },
  {
    ID: '15',
    Name: 'Umbral Torch',
    Weapon_Type: 'Sword',
    Rarity: '5',
    Base_ATK: '388',
    Stat_primary: 'INT',
    Stat_primary_value: '90',
    Stat_secondary: 'Arts DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Detonate: Covetous Stacks',
    Effect:
      'Triggering an Arts Burst gives the wielder ATK <b>+25.2%</b> for 30s. Trigger CD: 1s. Max stacks: <b>4</b> (each stack has its own duration).'
  },
  {
    ID: '16',
    Name: 'Fortmaker',
    Weapon_Type: 'Sword',
    Rarity: '5',
    Base_ATK: '351',
    Stat_primary: 'AGI',
    Stat_primary_value: '90',
    Stat_secondary: 'Crit Rate',
    Stat_secondary_value: '30%',
    Passive_name: 'Brutality: City Breaker',
    Effect:
      'Increases <u class="Physical">Physical DMG</u> dealt to Vulnerable enemies by <b>62.2%</b>.'
  },
  {
    ID: '17',
    Name: 'Sundering Steel',
    Weapon_Type: 'Sword',
    Rarity: '5',
    Base_ATK: '332',
    Stat_primary: 'AGI',
    Stat_primary_value: '90',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '45%',
    Passive_name: 'Combative: Anthem of Cinder',
    Effect:
      'Knocking Back the enemy also deals <b>350%</b> ATK of <u class="Physical">Physical DMG</u> and <b>27</b> Stagger.'
  },
  {
    ID: '18',
    Name: 'Grand Finale',
    Weapon_Type: 'Sword',
    Rarity: '4',
    Base_ATK: '291',
    Stat_primary: 'INT',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '32%',
    Passive_name: 'Pursuit: Circle Show',
    Effect:
      'Casting combo skills gives ATK <b>+58.8%</b> for 15s. Effect cannot stack.'
  },
  {
    ID: '19',
    Name: 'Contigent Measure',
    Weapon_Type: 'Sword',
    Rarity: '4',
    Base_ATK: '307',
    Stat_primary: 'AGI',
    Stat_primary_value: '63',
    Stat_secondary: 'Physical DMG',
    Stat_secondary_value: '35%',
    Passive_name: 'Suppression: Emergency Boost',
    Effect:
      'Hitting an enemy with a battle skill gives ATK <b>+37.6%</b> for 15s. Effect cannot stack.'
  },
  {
    ID: '20',
    Name: 'Tarr 11',
    Weapon_Type: 'Sword',
    Rarity: '3',
    Base_ATK: '254',
    Stat_primary: 'AGI',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '52',
    Passive_name: '',
    Effect: ''
  },
  {
    ID: '21',
    Name: 'Detonation Unit',
    Weapon_Type: 'Orbiter',
    Rarity: '6',
    Base_ATK: '490',
    Stat_primary: 'WILL',
    Stat_primary_value: '90',
    Stat_secondary: 'Nature DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Infliction: Corrosive Frenzy',
    Effect:
      'Corrosion lasts <b>50%</b> longer and reduces enemy DEF by another <b>43%</b>.'
  },
  {
    ID: '22',
    Name: 'Oblivion',
    Weapon_Type: 'Orbiter',
    Rarity: '6',
    Base_ATK: '423',
    Stat_primary: 'Main ATR',
    Stat_primary_value: '63',
    Stat_secondary: 'Ultimate gain eff.',
    Stat_secondary_value: '68%',
    Passive_name: 'Pursuit: Disgrace',
    Effect:
      'Target hit by the combo skill suffers Arts Resist <b>-28.0%</b>. Triggering the combo skill also gives the wielder ATK <b>+21.0%</b>. Duration: 15s. Effect can only trigger once every 18s.'
  },
  {
    ID: '23',
    Name: 'Chivalric Virtues',
    Weapon_Type: 'Orbiter',
    Rarity: '6',
    Base_ATK: '445',
    Stat_primary: 'INT',
    Stat_primary_value: '90',
    Stat_secondary: 'Electric DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Detonate: Re-Fuminate',
    Effect:
      'Increases <u class="Electric">Electric Burst DMG</u> dealt by <b>270%</b> and restores <b>28</b> SP. Effect can only trigger once every 3s.'
  },
  {
    ID: '24',
    Name: 'Monaihe',
    Weapon_Type: 'Orbiter',
    Rarity: '5',
    Base_ATK: '369',
    Stat_primary: 'WILL',
    Stat_primary_value: '90',
    Stat_secondary: 'Nature DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Pursuit: Field Teamwork',
    Effect:
      'Each enemy hit by the combo skill restores HP equal to (<b>160</b> + Will x <b>1.5</b>) to every operator on the field.'
  },
  {
    ID: '25',
    Name: 'Opus: Etch Figure',
    Weapon_Type: 'Orbiter',
    Rarity: '5',
    Base_ATK: '380',
    Stat_primary: 'WILL',
    Stat_primary_value: '90',
    Stat_secondary: 'Cryo DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Infliction: Ice Moraine',
    Effect:
      'After dealing <u class="Cryo">Cryo Infliction</u> to the enemy, the wielder gains ATK <b>+64.5%</b> for 10s. If the wielder deals Cryo Burst or Solification, the wielder gains ATK <b>+112.9%</b> instead. These effects cannot stack with each other.'
  },
  {
    ID: '26',
    Name: 'Stanza of Memorials',
    Weapon_Type: 'Orbiter',
    Rarity: '5',
    Base_ATK: '388',
    Stat_primary: 'INT',
    Stat_primary_value: '90',
    Stat_secondary: 'Heat DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Crusher: Exhausting Pyre',
    Effect:
      'Hitting enemies with Final Strike also reduces their <u class="Heat">Heat RES</u> by <b>26.9%</b> for 10s. Effect cannot stack.'
  },
  {
    ID: '27',
    Name: 'Wild Wanderer',
    Weapon_Type: 'Orbiter',
    Rarity: '5',
    Base_ATK: '362',
    Stat_primary: 'INT',
    Stat_primary_value: '90',
    Stat_secondary: 'Electric DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Detonate: Wild Blossom',
    Effect:
      'Triggering an Arts Burst gives the wielder Critical Rate <b>+16.8%</b> for 30s. Trigger CD: 1s. Max stacks: <b>4</b> (each stack has its own duration).'
  },
  {
    ID: '28',
    Name: 'Florescent Roc',
    Weapon_Type: 'Orbiter',
    Rarity: '4',
    Base_ATK: '307',
    Stat_primary: 'WILL',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '32%',
    Passive_name: 'Suppression: Emergency Boost',
    Effect:
      'Hitting an enemy with a battle skill gives ATK <b>+37.6%</b> for 15s. Effect cannot stack.'
  },
  {
    ID: '29',
    Name: 'Hypernova Auto',
    Weapon_Type: 'Orbiter',
    Rarity: '4',
    Base_ATK: '291',
    Stat_primary: 'INT',
    Stat_primary_value: '63',
    Stat_secondary: 'Arts DMG',
    Stat_secondary_value: '35%',
    Passive_name: 'Inspiring: Start of a Saga',
    Effect: 'When HP is above <b>80%</b>, ATK <b>+47.0%</b>.'
  },
  {
    ID: '30',
    Name: 'Jiminy 12',
    Weapon_Type: 'Orbiter',
    Rarity: '3',
    Base_ATK: '242',
    Stat_primary: 'INT',
    Stat_primary_value: '9',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '52',
    Passive_name: '',
    Effect: ''
  },
  {
    ID: '31',
    Name: 'Clannibal',
    Weapon_Type: 'Guns',
    Rarity: '6',
    Base_ATK: '401',
    Stat_primary: 'STR',
    Stat_primary_value: '90',
    Stat_secondary: 'Heat DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Infliction: Blaze of Vengeance',
    Effect:
      'Inflicting Combust to the enemy gives the wielder ATK <b>+144.0%</b> for 10s. Effect cannot stack.'
  },
  {
    ID: '32',
    Name: 'Wedge',
    Weapon_Type: 'Guns',
    Rarity: '6',
    Base_ATK: '490',
    Stat_primary: 'INT',
    Stat_primary_value: '90',
    Stat_secondary: 'Crit Rate',
    Stat_secondary_value: '30%',
    Passive_name: 'Fracture: Wedge of Civilization',
    Effect:
      'Scoring a critical hit gives the wielder All Arts DMG <b>+22.9%</b> for 10s. Max stacks: <b>5</b> (each stack has its own duration).'
  },
  {
    ID: '33',
    Name: 'Home Longing',
    Weapon_Type: 'Guns',
    Rarity: '6',
    Base_ATK: '445',
    Stat_primary: 'Main ATR',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '45%',
    Passive_name: 'Impetus: Reiterate',
    Effect:
      'Casting the same battle skill again gives All Damage <b>+120%</b> for the duration of the battle skill.'
  },
  {
    ID: '34',
    Name: 'Opus: Stress',
    Weapon_Type: 'Guns',
    Rarity: '5',
    Base_ATK: '406',
    Stat_primary: 'AGI',
    Stat_primary_value: '90',
    Stat_secondary: 'Crit DMG',
    Stat_secondary_value: '90%',
    Passive_name: 'Fracture: Shattering Overstress',
    Effect:
      'Scoring a critical hit with a skill gives the wielder ATK <b>+48.0%</b> for 10s. Max stacks: <b>5</b> (each stack has its own duration).'
  },
  {
    ID: '35',
    Name: 'Sculptor of Time',
    Weapon_Type: 'Guns',
    Rarity: '5',
    Base_ATK: '369',
    Stat_primary: 'INT',
    Stat_primary_value: '90',
    Stat_secondary: 'Crit Rate',
    Stat_secondary_value: '30%',
    Passive_name: 'Twilight: Her Journey',
    Effect:
      'Casting the ultimate gives the wielder All Damage <b>+139.7%</b> for 15s.'
  },
  {
    ID: '36',
    Name: 'Rational Farewell',
    Weapon_Type: 'Guns',
    Rarity: '5',
    Base_ATK: '369',
    Stat_primary: 'STR',
    Stat_primary_value: '90',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '45%',
    Passive_name: 'Infliction: Time Warp',
    Effect:
      'Successfully triggering an Arts Reaction restores HP equal to (<b>287</b> + Will x <b>2.7</b>) to the wielder and resets combo skill CD. Effect can only trigger once every 30s.'
  },
  {
    ID: '37',
    Name: 'Long Road',
    Weapon_Type: 'Guns',
    Rarity: '4',
    Base_ATK: '276',
    Stat_primary: 'INT',
    Stat_primary_value: '63',
    Stat_secondary: 'Arts DMG',
    Stat_secondary_value: '35%',
    Passive_name: 'Suppression: Emergency Boost',
    Effect:
      'Hitting an enemy with a battle skill gives ATK <b>+37.6%</b> for 15s. Effect cannot stack.'
  },
  {
    ID: '38',
    Name: 'Howling Guard',
    Weapon_Type: 'Guns',
    Rarity: '4',
    Base_ATK: '307',
    Stat_primary: 'STR',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '32%',
    Passive_name: 'Suppression: Emergency Boost',
    Effect:
      'Hitting an enemy with a battle skill gives ATK <b>+37.6%</b> for 15s. Effect cannot stack.'
  },
  {
    ID: '39',
    Name: 'Peco 5',
    Weapon_Type: 'Guns',
    Rarity: '3',
    Base_ATK: '242',
    Stat_primary: 'STR',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '52',
    Passive_name: '',
    Effect: ''
  },
  {
    ID: '40',
    Name: 'Mountain Bearer',
    Weapon_Type: 'Polearm',
    Rarity: '6',
    Base_ATK: '534',
    Stat_primary: 'AGI',
    Stat_primary_value: '90',
    Stat_secondary: 'Crit Rate',
    Stat_secondary_value: '30%',
    Passive_name: 'Combative: Weight of Mountain',
    Effect:
      'Knocking Dow, Lifting or Knocking Back an enemy also applies a mark to the enemy for 10s (effect cannot stack). When the same wielder hits a marked enemy with Final Strike, consume the mark and multiply the damage dealt by <b>13.</b>.'
  },
  {
    ID: '41',
    Name: 'JET',
    Weapon_Type: 'Polearm',
    Rarity: '6',
    Base_ATK: '445',
    Stat_primary: 'WILL',
    Stat_primary_value: '90',
    Stat_secondary: 'Electric DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Flow: Ad Astra',
    Effect:
      'The wielder\'s next battle skill or ultimate deals <b>154.6%</b> more <u class="Electric">Electric DMG</u> during casting. Effect can only trigger once every 10s.'
  },
  {
    ID: '42',
    Name: 'Vortex of Talos',
    Weapon_Type: 'Polearm',
    Rarity: '6',
    Base_ATK: '445',
    Stat_primary: 'Main ATR',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '45%',
    Passive_name: 'Suppression: Cyclic Observation',
    Effect:
      "Casting battle skills restores <b>21</b> SP and resets <b>27%</b> of the wielder's combo skill CD. Effect can only trigger once every 12s."
  },
  {
    ID: '43',
    Name: 'Valiant',
    Weapon_Type: 'Polearm',
    Rarity: '5',
    Base_ATK: '406',
    Stat_primary: 'AGI',
    Stat_primary_value: '90',
    Stat_secondary: 'Crit Rate',
    Stat_secondary_value: '30%',
    Passive_name: 'Fracture: Virtuous Gain',
    Effect:
      'Scoring a critical hit with a skill restores <b>19</b> Ultimate Energy and gives ATK <b>+35%</b> for 10s to the wielder. Effect can only trigger once every 15s.'
  },
  {
    ID: '44',
    Name: 'Rock Auger',
    Weapon_Type: 'Polearm',
    Rarity: '5',
    Base_ATK: '358',
    Stat_primary: 'WILL',
    Stat_primary_value: '90',
    Stat_secondary: 'Arts DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Pursuit: Skill Synergy',
    Effect:
      'Casting combo skills gives the wielder All Damage <b>+131.3%</b> during the casting of the next battle skill.'
  },
  {
    ID: '45',
    Name: 'Chimeric Justice',
    Weapon_Type: 'Polearm',
    Rarity: '5',
    Base_ATK: '369',
    Stat_primary: 'AGI',
    Stat_primary_value: '90',
    Stat_secondary: 'Physical DMG',
    Stat_secondary_value: '50%',
    Passive_name: 'Brutality: Cemented Fury',
    Effect:
      'Making enemies Vulnerable gives the wielder ATK <b>+20.2%</b> for 30s. Max stacks: <b>5</b> (each stack has its own duration).'
  },
  {
    ID: '46',
    Name: 'Aggeloslayer',
    Weapon_Type: 'Polearm',
    Rarity: '4',
    Base_ATK: '276',
    Stat_primary: 'WILL',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '32%',
    Passive_name: 'Suppression: Emergency Boost',
    Effect:
      'Hitting an enemy with a battle skill gives ATK <b>+37.6%</b> for 15s. Effect cannot stack.'
  },
  {
    ID: '47',
    Name: "Pathfinder's Beacon",
    Weapon_Type: 'Polearm',
    Rarity: '4',
    Base_ATK: '307',
    Stat_primary: 'AGI',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '32%',
    Passive_name: 'Inspiring: Start of a Saga',
    Effect: 'When HP is above <b>80%</b>, ATK <b>+47.0%</b>.'
  },
  {
    ID: '48',
    Name: 'Opero 77',
    Weapon_Type: 'Polearm',
    Rarity: '3',
    Base_ATK: '254',
    Stat_primary: 'WILL',
    Stat_primary_value: '63',
    Stat_secondary: 'ATK',
    Stat_secondary_value: '52',
    Passive_name: '',
    Effect: ''
  }
];
