import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

interface IProps {
  id: string;
}

export const EndfieldWeaponImage: React.FC<IProps> = ({ id }) => {
  return (
    <>
      {id === '1' && (
        <StaticImage
          src="../../../images/endfield/weapons/1.webp"
          alt="Weapon"
        />
      )}
      {id === '2' && (
        <StaticImage
          src="../../../images/endfield/weapons/2.webp"
          alt="Weapon"
        />
      )}
      {id === '3' && (
        <StaticImage
          src="../../../images/endfield/weapons/3.webp"
          alt="Weapon"
        />
      )}
      {id === '4' && (
        <StaticImage
          src="../../../images/endfield/weapons/4.webp"
          alt="Weapon"
        />
      )}
      {id === '5' && (
        <StaticImage
          src="../../../images/endfield/weapons/5.webp"
          alt="Weapon"
        />
      )}
      {id === '6' && (
        <StaticImage
          src="../../../images/endfield/weapons/6.webp"
          alt="Weapon"
        />
      )}
      {id === '7' && (
        <StaticImage
          src="../../../images/endfield/weapons/7.webp"
          alt="Weapon"
        />
      )}
      {id === '8' && (
        <StaticImage
          src="../../../images/endfield/weapons/8.webp"
          alt="Weapon"
        />
      )}
      {id === '9' && (
        <StaticImage
          src="../../../images/endfield/weapons/9.webp"
          alt="Weapon"
        />
      )}
      {id === '10' && (
        <StaticImage
          src="../../../images/endfield/weapons/10.webp"
          alt="Weapon"
        />
      )}
      {id === '11' && (
        <StaticImage
          src="../../../images/endfield/weapons/11.webp"
          alt="Weapon"
        />
      )}
      {id === '12' && (
        <StaticImage
          src="../../../images/endfield/weapons/12.webp"
          alt="Weapon"
        />
      )}
      {id === '13' && (
        <StaticImage
          src="../../../images/endfield/weapons/13.webp"
          alt="Weapon"
        />
      )}
      {id === '14' && (
        <StaticImage
          src="../../../images/endfield/weapons/14.webp"
          alt="Weapon"
        />
      )}
      {id === '15' && (
        <StaticImage
          src="../../../images/endfield/weapons/15.webp"
          alt="Weapon"
        />
      )}
      {id === '16' && (
        <StaticImage
          src="../../../images/endfield/weapons/16.webp"
          alt="Weapon"
        />
      )}
      {id === '17' && (
        <StaticImage
          src="../../../images/endfield/weapons/17.webp"
          alt="Weapon"
        />
      )}
      {id === '18' && (
        <StaticImage
          src="../../../images/endfield/weapons/18.webp"
          alt="Weapon"
        />
      )}
      {id === '19' && (
        <StaticImage
          src="../../../images/endfield/weapons/19.webp"
          alt="Weapon"
        />
      )}
      {id === '20' && (
        <StaticImage
          src="../../../images/endfield/weapons/20.webp"
          alt="Weapon"
        />
      )}
      {id === '21' && (
        <StaticImage
          src="../../../images/endfield/weapons/21.webp"
          alt="Weapon"
        />
      )}
      {id === '22' && (
        <StaticImage
          src="../../../images/endfield/weapons/22.webp"
          alt="Weapon"
        />
      )}
      {id === '23' && (
        <StaticImage
          src="../../../images/endfield/weapons/23.webp"
          alt="Weapon"
        />
      )}
      {id === '24' && (
        <StaticImage
          src="../../../images/endfield/weapons/24.webp"
          alt="Weapon"
        />
      )}
      {id === '25' && (
        <StaticImage
          src="../../../images/endfield/weapons/25.webp"
          alt="Weapon"
        />
      )}
      {id === '26' && (
        <StaticImage
          src="../../../images/endfield/weapons/26.webp"
          alt="Weapon"
        />
      )}
      {id === '27' && (
        <StaticImage
          src="../../../images/endfield/weapons/27.webp"
          alt="Weapon"
        />
      )}
      {id === '28' && (
        <StaticImage
          src="../../../images/endfield/weapons/28.webp"
          alt="Weapon"
        />
      )}
      {id === '29' && (
        <StaticImage
          src="../../../images/endfield/weapons/29.webp"
          alt="Weapon"
        />
      )}
      {id === '30' && (
        <StaticImage
          src="../../../images/endfield/weapons/30.webp"
          alt="Weapon"
        />
      )}
      {id === '31' && (
        <StaticImage
          src="../../../images/endfield/weapons/31.webp"
          alt="Weapon"
        />
      )}
      {id === '32' && (
        <StaticImage
          src="../../../images/endfield/weapons/32.webp"
          alt="Weapon"
        />
      )}
      {id === '33' && (
        <StaticImage
          src="../../../images/endfield/weapons/33.webp"
          alt="Weapon"
        />
      )}
      {id === '34' && (
        <StaticImage
          src="../../../images/endfield/weapons/34.webp"
          alt="Weapon"
        />
      )}
      {id === '35' && (
        <StaticImage
          src="../../../images/endfield/weapons/35.webp"
          alt="Weapon"
        />
      )}
      {id === '36' && (
        <StaticImage
          src="../../../images/endfield/weapons/36.webp"
          alt="Weapon"
        />
      )}
      {id === '37' && (
        <StaticImage
          src="../../../images/endfield/weapons/37.webp"
          alt="Weapon"
        />
      )}
      {id === '38' && (
        <StaticImage
          src="../../../images/endfield/weapons/38.webp"
          alt="Weapon"
        />
      )}
      {id === '39' && (
        <StaticImage
          src="../../../images/endfield/weapons/39.webp"
          alt="Weapon"
        />
      )}
      {id === '40' && (
        <StaticImage
          src="../../../images/endfield/weapons/40.webp"
          alt="Weapon"
        />
      )}
      {id === '41' && (
        <StaticImage
          src="../../../images/endfield/weapons/41.webp"
          alt="Weapon"
        />
      )}
      {id === '42' && (
        <StaticImage
          src="../../../images/endfield/weapons/42.webp"
          alt="Weapon"
        />
      )}
      {id === '43' && (
        <StaticImage
          src="../../../images/endfield/weapons/43.webp"
          alt="Weapon"
        />
      )}
      {id === '44' && (
        <StaticImage
          src="../../../images/endfield/weapons/44.webp"
          alt="Weapon"
        />
      )}
      {id === '45' && (
        <StaticImage
          src="../../../images/endfield/weapons/45.webp"
          alt="Weapon"
        />
      )}
      {id === '46' && (
        <StaticImage
          src="../../../images/endfield/weapons/46.webp"
          alt="Weapon"
        />
      )}
      {id === '47' && (
        <StaticImage
          src="../../../images/endfield/weapons/47.webp"
          alt="Weapon"
        />
      )}
      {id === '48' && (
        <StaticImage
          src="../../../images/endfield/weapons/48.webp"
          alt="Weapon"
        />
      )}
    </>
  );
};
