import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import './weapons.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { AKEWeapons } from '../../modules/endfield/data/endfield-weapons';
import lodash from 'lodash';
import { EndfieldWeaponInfo } from '../../modules/endfield/components/endfield-weapon-info';
import { Form } from 'react-bootstrap';

const EndfieldWeaponsPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [currentRarity, setCurrentRarity] = useState('All');
  const [currentType, setCurrentType] = useState('All');
  const [currentSpecial, setCurrentSpecial] = useState('All');
  const [visibleTraces, setVisibleTraces] = useState(AKEWeapons);
  const [textSearch, setTextSearch] = useState('');

  const rarityChangeHandler = (e) => {
    setCurrentRarity(e);
  };

  const typeChangeHandler = (e) => {
    setCurrentType(e);
  };

  const specialChangeHandler = (e) => {
    setCurrentSpecial(e);
  };

  useEffect(() => {
    let filtered = AKEWeapons;

    filtered = filtered.sort((a, b) => {
      let retval = 0;
      if (a.Rarity > b.Rarity) retval = -1;
      if (a.Rarity < b.Rarity) retval = 1;
      if (retval === 0) retval = a.Name < b.Name ? -1 : 1;
      return retval;
    });

    setVisibleTraces(filtered);
    forceUpdate();
  }, []);

  useEffect(() => {
    let filtered = AKEWeapons;

    if (currentRarity != 'All') {
      filtered = filtered
        .sort((a, b) => {
          let retval = 0;
          if (a.Rarity > b.Rarity) retval = -1;
          if (a.Rarity < b.Rarity) retval = 1;
          if (retval === 0) retval = a.Name < b.Name ? -1 : 1;
          return retval;
        })
        .filter((weapon) => {
          if (weapon.Rarity === currentRarity) return true;
        });
    }

    if (currentType != 'All') {
      filtered = filtered
        .sort((a, b) => {
          let retval = 0;
          if (a.Rarity > b.Rarity) retval = -1;
          if (a.Rarity < b.Rarity) retval = 1;
          if (retval === 0) retval = a.Name < b.Name ? -1 : 1;
          return retval;
        })
        .filter((weapon) => {
          if (weapon.Weapon_Type === currentType) return true;
        });
    }

    if (currentSpecial != 'All') {
      filtered = filtered
        .sort((a, b) => {
          let retval = 0;
          if (a.Rarity > b.Rarity) retval = -1;
          if (a.Rarity < b.Rarity) retval = 1;
          if (retval === 0) retval = a.Name < b.Name ? -1 : 1;
          return retval;
        })
        .filter((weapon) => {
          if (weapon.Stat_primary === currentSpecial) return true;
          if (weapon.Stat_secondary === currentSpecial) return true;
        });
    }

    if (textSearch != '') {
      filtered = filtered.filter(
        (echo) =>
          echo.Name &&
          echo.Name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1
      );
      filtered = lodash.uniqBy(filtered, 'ID');
    }

    setVisibleTraces(filtered);
    forceUpdate();
  }, [currentRarity, currentType, textSearch, currentSpecial]);

  const items = visibleTraces.map((weapon, idx) => {
    return (
      <div key={idx} className="endfield-weapon-box">
        <EndfieldWeaponInfo name={weapon.Name} mode="data" />
      </div>
    );
  });
  return (
    <DashboardLayout
      className={'generic-page endfield endfield-weapons-page'}
      game="endfield"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>Weapons List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/endfield/categories/category_weapons.webp"
            alt="Weapons"
          />
        </div>
        <div className="page-details">
          <h1>Arknights: Endfield Weapons List</h1>
          <h2>
            List of all weapons available in Arknights: Endfield (based on
            current CBT).
          </h2>
          <p>
            Last updated: <strong>23/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      {/* <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Arknights: Endfield Database is currently being created!</h4>
          <p>
            We're gathering information on the current CBT now and the site will
            be updated soon!
          </p>
        </div>
      </div> */}
      <SectionHeader
        title="Weapons"
        subtitle={
          <span className="additional-text">
            Showing {visibleTraces.length} weapon
            {visibleTraces.length !== 1 && 's'}
          </span>
        }
      />
      <Form.Control
        size="lg"
        type="text"
        placeholder="Search for Weapons"
        value={textSearch}
        onChange={(e) => setTextSearch(e.target.value)}
      />
      <div className="filters-row">
        <div className="weapon-filter rarity">
          <div
            className={`option all ${currentRarity === 'All' && 'selected'}`}
            onClick={() => rarityChangeHandler('All')}
          >
            <h6>All Rarities</h6>
          </div>
          <div
            className={`option rarity-3 ${currentRarity === '3' && 'selected'}`}
            onClick={() => rarityChangeHandler('3')}
          >
            <h6>3✦</h6>
          </div>
          <div
            className={`option rarity-4 ${currentRarity === '4' && 'selected'}`}
            onClick={() => rarityChangeHandler('4')}
          >
            <h6>4✦</h6>
          </div>
          <div
            className={`option rarity-5 ${currentRarity === '5' && 'selected'}`}
            onClick={() => rarityChangeHandler('5')}
          >
            <h6>5✦</h6>
          </div>
          <div
            className={`option rarity-6 ${currentRarity === '6' && 'selected'}`}
            onClick={() => rarityChangeHandler('6')}
          >
            <h6>6✦</h6>
          </div>
        </div>
        <div className="weapon-filter type">
          <div
            className={`option all ${currentType === 'All' && 'selected'}`}
            onClick={() => typeChangeHandler('All')}
          >
            <h6>All Types</h6>
          </div>
          <div
            className={`option ${currentType === 'Great Sword' && 'selected'}`}
            onClick={() => typeChangeHandler('Great Sword')}
          >
            <StaticImage
              src="../../images/endfield/icons/weapon_greatsword.webp"
              width={24}
              alt="Great Sword"
            />
          </div>
          <div
            className={`option ${currentType === 'Guns' && 'selected'}`}
            onClick={() => typeChangeHandler('Guns')}
          >
            <StaticImage
              src="../../images/endfield/icons/weapon_guns.webp"
              width={24}
              alt="Guns"
            />
          </div>
          <div
            className={`option ${currentType === 'Orbiter' && 'selected'}`}
            onClick={() => typeChangeHandler('Orbiter')}
          >
            <StaticImage
              src="../../images/endfield/icons/weapon_orbiter.webp"
              width={24}
              alt="Orbiter"
            />
          </div>
          <div
            className={`option ${currentType === 'Polearm' && 'selected'}`}
            onClick={() => typeChangeHandler('Polearm')}
          >
            <StaticImage
              src="../../images/endfield/icons/weapon_polearm.webp"
              width={24}
              alt="Polearm"
            />
          </div>
          <div
            className={`option ${currentType === 'Sword' && 'selected'}`}
            onClick={() => typeChangeHandler('Sword')}
          >
            <StaticImage
              src="../../images/endfield/icons/weapon_sword.webp"
              width={24}
              alt="Sword"
            />
          </div>
        </div>
      </div>
      <div className="special-filter-row">
        <div className="weapon-filter special">
          <div
            className={`option all ${currentSpecial === 'All' && 'selected'}`}
            onClick={() => specialChangeHandler('All')}
          >
            <h6>All Tags</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'ATK' && 'selected'}`}
            onClick={() => specialChangeHandler('ATK')}
          >
            <h6>ATK</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'HP' && 'selected'}`}
            onClick={() => specialChangeHandler('HP')}
          >
            <h6>HP</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'Crit Rate' && 'selected'}`}
            onClick={() => specialChangeHandler('Crit Rate')}
          >
            <h6>Crit Rate</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'Crit DMG' && 'selected'}`}
            onClick={() => specialChangeHandler('Crit DMG')}
          >
            <h6>Crit DMG</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'Main ATR' && 'selected'}`}
            onClick={() => specialChangeHandler('Main ATR')}
          >
            <h6>Main ATR</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'AGI' && 'selected'}`}
            onClick={() => specialChangeHandler('AGI')}
          >
            <h6>AGI</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'INT' && 'selected'}`}
            onClick={() => specialChangeHandler('INT')}
          >
            <h6>INT</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'STR' && 'selected'}`}
            onClick={() => specialChangeHandler('STR')}
          >
            <h6>STR</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'WILL' && 'selected'}`}
            onClick={() => specialChangeHandler('WILL')}
          >
            <h6>WILL</h6>
          </div>
          <div
            className={`option Cryo ${
              currentSpecial === 'Cryo DMG' && 'selected'
            }`}
            onClick={() => specialChangeHandler('Cryo DMG')}
          >
            <h6>Cryo DMG</h6>
          </div>
          <div
            className={`option Electric ${
              currentSpecial === 'Electric DMG' && 'selected'
            }`}
            onClick={() => specialChangeHandler('Electric DMG')}
          >
            <h6>Electric DMG</h6>
          </div>
          <div
            className={`option Heat ${
              currentSpecial === 'Heat DMG' && 'selected'
            }`}
            onClick={() => specialChangeHandler('Heat DMG')}
          >
            <h6>Heat DMG</h6>
          </div>
          <div
            className={`option Nature ${
              currentSpecial === 'Nature DMG' && 'selected'
            }`}
            onClick={() => specialChangeHandler('Nature DMG')}
          >
            <h6>Nature DMG</h6>
          </div>
          <div
            className={`option Physical ${
              currentSpecial === 'Physical DMG' && 'selected'
            }`}
            onClick={() => specialChangeHandler('Physical DMG')}
          >
            <h6>Physical DMG</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'Arts DMG' && 'selected'}`}
            onClick={() => specialChangeHandler('Arts DMG')}
          >
            <h6>Arts DMG</h6>
          </div>
          <div
            className={`option ${currentSpecial === 'Basic DMG' && 'selected'}`}
            onClick={() => specialChangeHandler('Basic DMG')}
          >
            <h6>Basic DMG</h6>
          </div>
          <div
            className={`option ${
              currentSpecial === 'Ultimate gain eff.' && 'selected'
            }`}
            onClick={() => specialChangeHandler('Ultimate gain eff.')}
          >
            <h6>Ultimate gain eff.</h6>
          </div>
        </div>
      </div>
      <div className="endfield-weapons-container">{items}</div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default EndfieldWeaponsPage;

export const Head: React.FC = () => (
  <Seo
    title="Weapons | Arknights: Endfield | Prydwen Institute"
    description="List of all weapons available in Arknights: Endfield (based on current CBT)."
    game="endfield"
  />
);
