/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useReducer, useState } from 'react';
import './endfield-weapon-box.scss';
import { IEndfieldWeapon } from '../../common/model/graphql-types';
import { EndfieldWeaponImage } from './endfield-weapon-image';

interface IProps {
  weapon: IEndfieldWeapon;
  onProfile?: boolean;
}

export const EndfieldWeaponBox: React.FC<IProps> = ({ weapon, onProfile }) => {
  return (
    <>
      {onProfile ? (
        <div className="endfield-weapon-compact">
          <strong className={`avatar ${weapon.Rarity ? weapon.Rarity : ''}`}>
            <EndfieldWeaponImage id={weapon.ID} />
          </strong>{' '}
          <strong className={`type ${weapon.Rarity ? weapon.Rarity : ''}`}>
            {weapon.Name}
          </strong>
        </div>
      ) : (
        <>
          <div className="endfield-weapon-box box">
            <div className="endfield-header">
              <div className={`endfield-image rarity-${weapon.Rarity}`}>
                <EndfieldWeaponImage id={weapon.ID} />
              </div>
              <div className="endfield-data">
                <h4>{weapon.Name}</h4>
                <div className="endfield-info">
                  <p>
                    Rarity:{' '}
                    <strong
                      className={`rarity-endfield rarity-${weapon.Rarity}`}
                    >
                      {weapon.Rarity}✦
                    </strong>
                  </p>
                  <p>
                    Type: <strong>{weapon.Weapon_Type}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="endfield-content">
              {weapon.Effect != '' ? (
                <>
                  <div
                    className={`skill-with-coloring`}
                    dangerouslySetInnerHTML={{
                      __html: weapon.Effect
                    }}
                  />
                </>
              ) : (
                <>
                  <div className={`skill-with-coloring`}>
                    This Weapon has no passive effect.
                  </div>
                </>
              )}
            </div>
            <div className="endfield-stats">
              <p className="first">
                ATK: +<strong>{weapon.Base_ATK}</strong>
              </p>
              <p className={`second ${weapon.Stat_primary}`}>
                {weapon.Stat_primary}: +
                <strong>{weapon.Stat_primary_value}</strong>
              </p>
              <p className={`third ${weapon.Stat_secondary}`}>
                {weapon.Stat_secondary}: +
                <strong>{weapon.Stat_secondary_value}</strong>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
